import React, { ReactChildren } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const StyledGatsbyLink = styled(GatsbyLink)``

const StyledLink = styled.a``

const StyledSpan = styled.span``

interface LinkProps {
  to: string
  href: string
  children: ReactChildren
}

export const Link = ({ to, href, children, ...props }: LinkProps) => {
  if (to) {
    if (to.indexOf("h") === -1 && to.indexOf("/") !== 0) {
      to = `/${to}`
    }
    return (
      <StyledGatsbyLink to={to} {...props}>
        {children}
      </StyledGatsbyLink>
    )
  } else if (href) {
    return (
      <StyledLink href={href} {...props}>
        {children}
      </StyledLink>
    )
  }
  return <StyledSpan {...props}>{children}</StyledSpan>
}
