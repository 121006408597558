import * as React from "react"
import styled, { css } from "styled-components"
import { darken } from "polished"

import {
  MaxWidthContainer,
  Row,
  Col,
  media,
  HurleyThemeProps,
  Typography,
  Picture,
} from "@hurleymc/components"

import { Link } from "./link"

const Footer = styled.footer`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mercury};
  display: block;
  width: 100%;
`

const FooterMenu = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaft};
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mineShaftContrast};
  padding: 6rem 1rem;
  font-size: 0.875rem;

  ul {
    list-style: none;
    margin: 0.5rem 0;
    padding: 0;
  }
  li {
    margin-bottom: 0.25rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
    &:hover {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    }
  }
  img {
    max-height: 4rem;
  }
  .image-list {
    li {
      margin-bottom: 1rem;
    }
  }

  ${media.breakpoint.down(
    "lg",
    css`
      text-align: center;
      ul {
        margin-bottom: 4rem;
      }
    `
  )}
`

const SocialBar = styled.div`
  align-items: center;
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 3rem 1rem;

  ${media.breakpoint.up(
    "lg",
    css`
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
    `
  )}

  img {
    display: block;
    width: 3rem;
  }

  .social-icons {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 1rem;
    ${media.breakpoint.up(
      "lg",
      css`
        margin-top: 0;
        margin-left: 1rem;
        justify-content: flex-start;
        width: auto;
      `
    )}
  }

  a {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
    display: inline-block;
    margin: 0 1rem;
    &:hover {
      color: ${({ theme }: HurleyThemeProps) =>
        darken(0.15, theme.colors.named.white)};
    }
  }
`

const LogoBlock = styled.div`
  display: flex;
  justify-content: row;
  flex-wrap: nowrap;
`

const LogoImage = styled.img`
  height: 2rem;

  ${media.breakpoint.up(
    "md",
    css`
      height: 2rem;
    `
  )}

  ${media.breakpoint.up(
    "lg",
    css`
      height: 2rem;
    `
  )}
`

const InfoBlock = styled.div`
  align-items: center;
  color: #666;
  display: flex;
  font-size: 0.7rem;
  padding: 3rem 1rem;
  flex-flow: column nowrap;
  ${media.breakpoint.up(
    "lg",
    css`
      flex-flow: row nowrap;
      justify-content: space-between;
    `
  )}
`

export default ({ className = null, ...props }) => {
  return (
    <MaxWidthContainer className={className} {...props}>
      <Row>
        <Footer>
          <FooterMenu>
            <Row xsJustifyContentCenter={true}>
              <Col xs={1} lg={1 / 6}>
                <Typography
                  color="inherit"
                  variant="heading-5"
                  textTransform="none"
                >
                  Connect With Us
                </Typography>
                <ul>
                  <li>
                    <Link
                      href="https://www.facebook.com/HurleyGME"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GME on Facebook
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col xs={1} lg={1 / 6}>
                <Typography
                  color="inherit"
                  variant="heading-5"
                  textTransform="none"
                >
                  Medical Education
                </Typography>
                <ul>
                  <li>
                    <Link to="/gme">Graduate Medical Educ.</Link>
                  </li>
                  <li>
                    <Link to="/other-learners">Other Learners</Link>
                  </li>
                  <li>
                    <Link to="/cme">Continuing Medical Education</Link>
                  </li>
                  <li>
                    <Link to="/research">Research</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={1} lg={1 / 6}>
                <Typography
                  color="inherit"
                  variant="heading-5"
                  textTransform="none"
                >
                  Quick Links
                </Typography>
                <ul>
                  <li>
                    <Link to="/gme/residencies-and-fellowships/">
                      Residencies &amp; Fellowships
                    </Link>
                  </li>
                  <li>
                    <Link to="/gme/physician-connection/">
                      Physician Connection
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog/graduate-medical-education">GME Blog</Link>
                  </li>
                  <li>
                    <Link to="/gme/faq/">GME FAQs</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={1} lg={1 / 6}>
                <Typography
                  color="inherit"
                  variant="heading-5"
                  textTransform="none"
                >
                  Hurley Network
                </Typography>
                <ul>
                  <li>
                    <Link
                      href="http://www.hurleymc.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hurley Medical Center
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="http://www.hurleyfoundation.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hurley Foundation
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="http://education.hurleymc.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Medical Education
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col xs={1} lg={1 / 3}>
                <Typography
                  color="inherit"
                  variant="heading-5"
                  textTransform="none"
                >
                  Affiliated Universities
                </Typography>
                <ul className="image-list">
                  <li>
                    <Link
                      href="http://humanmedicine.msu.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Picture
                        alt="Michigan State University Logo"
                        src="https://images.files.edit.hurleymc.com/GME/spartan_logo_white.png"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="http://www.med.umich.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Picture
                        alt="University of Michgian Logo"
                        src="https://images.files.edit.hurleymc.com/GME/UMHS_logo_white.png"
                      />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </FooterMenu>

          <SocialBar>
            <Typography variant="heading-4" color="inherit">
              Follow Hurley
            </Typography>
            <div className="social-icons">
              <Link
                href="https://twitter.com/HurleyMedical/"
                target="_blank"
                title="HurleyMedical on Twitter"
                rel="noopener noreferrer"
              >
                <i className="fab fa-2x fa-twitter" />
              </Link>
              <Link
                href="https://www.instagram.com/hurleymedicalcenter/"
                target="_blank"
                title="hurleymedicalcenter on Instagram"
                rel="noopener noreferrer"
              >
                <i className="fab fa-2x fa-instagram" />
              </Link>
              <Link
                href="https://www.youtube.com/user/HurleyMedicalCenter1/"
                target="_blank"
                title="HurleyMedicalCenter1 on Youtube"
                rel="noopener noreferrer"
              >
                <i className="fab fa-2x fa-youtube" />
              </Link>
              <Link
                href="https://www.facebook.com/HurleyMedicalCenter/"
                target="_blank"
                title="HurleyMedicalCenter on Facebook"
                rel="noopener noreferrer"
              >
                <i className="fab fa-2x fa-facebook-square" />
              </Link>
              <Link
                href="https://www.linkedin.com/company/hurley-medical-center/"
                target="_blank"
                title="hurley-medical-center on LinkedIn"
                rel="noopener noreferrer"
              >
                <i className="fab fa-2x fa-linkedin" />
              </Link>
            </div>
          </SocialBar>

          <InfoBlock>
            <div>
              Copyright © {new Date().getFullYear()} Hurley Medical Center
            </div>

            <LogoBlock>
              <Link
                href="http://www.hurleymc.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoImage
                  src="https://images.files.edit.hurleymc.com/Logos/hurley-medical-education.png"
                  alt=""
                />
              </Link>
            </LogoBlock>
          </InfoBlock>
        </Footer>
      </Row>
    </MaxWidthContainer>
  )
}
