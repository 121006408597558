import * as React from "react"
import styled from "styled-components"
import { readableColor } from "polished"

import {
  AspectRatio,
  Col,
  Container,
  Icon,
  Picture,
  Row,
  Spacer,
  Typography,
  HurleyThemeProps,
} from "@hurleymc/components"
import { selectFirst } from "@hurleymc/sdk/selectors"

import theme from "../theme"

import { DefaultProfileImage } from "./default-profile-image"
import { Link } from "./link"

const Center = styled(Row)`
  display: block;
  margin: auto;
  text-align: center;
`

const Half = styled.div`
  margin: auto;
  width: 50%;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Image = styled.div`
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const CardContainer = styled.div`
  height: 100%;
  padding-bottom: 1rem;
`

const Card = styled(Link)`
  border: 1px solid
    ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  display: block;
  height: 100%;
`

const Bar = styled.div`
  height: 1px;
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.silver};
  width: 100%;
`

const TypeBox = styled.div`
  line-height: 0;
  text-align: center;
  margin-top: -1rem;
`

const Box = styled(Typography)`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: ${({ theme }: HurleyThemeProps) =>
    readableColor(
      theme.colors.primary,
      theme.colors.named.white,
      theme.colors.named.black
    )};
  line-height: 0;
  padding: 1rem 1rem;
`

const VerticalAlignItems = styled.div`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;

  .material-icons {
    font-size: 1.25rem;
    margin-right: 1rem;
  }
`

const LinkText = styled(Typography)`
  line-height: 1.125;
`

const PersonName = styled.div`
  text-align: center;
`

export const PersonCard = props => {
  if (props === null || typeof props !== "object") {
    return null
  }

  return (
    <Container className={props.className}>
      <Spacer margin="4rem .5rem">
        <Spacer margin="0 0 1.5rem">
          <Center>
            <Typography variant="heading-6">{props.identifier}</Typography>
          </Center>
        </Spacer>
        <Row xsJustifyContentCenter={true}>
          {Array.isArray(props.directory) &&
            props.directory.length > 0 &&
            props.directory.map((contact, key) => {
              const category = selectFirst(contact.contactType)
              const image: any = selectFirst(contact.contactImage)
              return (
                <Col
                  xs={1}
                  md={1 / 2}
                  lg={1 / 3}
                  xl={1 / 4}
                  key={contact.id || key}
                >
                  <CardContainer>
                    <Card to={`/${contact.uri}`}>
                      <Half>
                        <AspectRatio ratio="1:1">
                          <Image>
                            {image.url ? (
                              <Picture
                                alt={image.title}
                                sources={[
                                  {
                                    media: `(min-width: ${theme.breakpoints.lg}px)`,
                                    srcSet: image.lg,
                                  },
                                ]}
                                src={image.sm || image.url}
                              />
                            ) : (
                              <DefaultProfileImage />
                            )}
                            <Bar />
                          </Image>
                        </AspectRatio>
                      </Half>
                      {props.showCategory && category && category.title && (
                        <TypeBox>
                          <Box variant="button">{category.title}</Box>
                        </TypeBox>
                      )}
                      <Spacer padding="1rem">
                        <PersonName>
                          <Typography variant="heading-4">
                            {contact.fullName}
                          </Typography>
                          {contact.honorificSuffix && (
                            <Typography variant="heading-5">
                              {contact.honorificSuffix}
                            </Typography>
                          )}
                        </PersonName>
                        {(contact.email || contact.phoneNumber) && (
                          <Spacer padding="1.5rem 0 0">
                            {contact.email && (
                              <VerticalAlignItems>
                                <Icon icon="email" />
                                <LinkText
                                  color="inherit"
                                  component="a"
                                  dangerouslySetInnerHTML={{
                                    __html: contact.email
                                      .split("@")
                                      .join("<br/>@"),
                                  }}
                                  variant="button"
                                  href={`mailto:${contact.email}`}
                                />
                              </VerticalAlignItems>
                            )}
                            {contact.phoneNumber &&
                              contact.phoneNumber.national &&
                              contact.phoneNumber.national !== "" && (
                                <VerticalAlignItems>
                                  <Icon icon="phone" />
                                  <LinkText
                                    color="inherit"
                                    component="a"
                                    variant="button"
                                    href={contact.phoneNumber.RFC3966}
                                  >
                                    {contact.phoneNumber.national}
                                  </LinkText>
                                </VerticalAlignItems>
                              )}
                          </Spacer>
                        )}
                      </Spacer>
                    </Card>
                  </CardContainer>
                </Col>
              )
            })}
        </Row>
      </Spacer>
    </Container>
  )
}
